-<template>
    <div>
        <hero/>
        <contents :youth="youth" :youthAspects="youthAspects"/>
    </div>
</template>

<script>
    import axios from 'axios'
    export default {
      name: 'Youth',
        components: {
            Hero: () => import ('@/components/youth/Hero'),
            Contents: () => import('@/components/youth/Contents')
        },
        data: () => ({
            youth: {},
            youthAspects: null
        }),
        mounted(){
            this.$store.dispatch('app/setLink', 4)
            },
        created() {
          const youth = axios.get('https://ktt01.de/wordpress/?rest_route=/youth/post')
          const youthAspects = axios.get('https://ktt01.de/wordpress/?rest_route=/youth-aspects/post&order=asc')

          axios.all([youth,youthAspects])
              .then(axios.spread((...responses) => {
                const resYouth = responses[0].data[0]
                const resYouthAspects = responses[1].data

                this.youth = resYouth.acf

                this.youthAspects = resYouthAspects

              })).catch(errors => {
            console.error(errors)
            this.aboutLoading =false
            this.executivesLoading = false
          })
        }
    }
</script>


const myUploadProgress = (Loading) => (progress) =>
{
let percentage = Math.floor((progress.loaded * 100) / progress.total)
Loading = percentage
}
